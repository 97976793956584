<template>
  <div id="app" name="app" ref="app">
    <!-- 顶栏容器 -->
    <div class="header">
      <TopNav></TopNav>
    </div>
    <!-- 顶栏容器END -->
    <div class="main-div">
      <!-- 主要区域容器 -->
      <div class="main">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
      <!-- 主要区域容器END -->
      <!-- 底栏容器 -->
      <div class="footer" id="footer">
        <BotFooter></BotFooter>
      </div>
    </div>

    <!-- 底栏容器END -->
    <!-- 即时通讯 -->
    <div class="online-part" v-if="!dialogVisible" @click="openOnline()">
      <div>Chat to us now</div>
      <i class="el-icon-chat-square"></i>
    </div>
    <!-- <div
      class="all-page-bg"
      v-if="dialogVisible"
      @click="$store.commit('showIframe', false)"
    ></div> -->
    <div v-if="dialogVisible" class="iframe-visible">
      <!-- 在弹窗中嵌入 iframe -->
      <iframe
        :src="iframeUrl"
        width="400px"
        height="560px"
        frameborder="0"
      ></iframe>
      <i
        class="el-icon-minus iframe-icon"
        @click="$store.commit('showIframe', false)"
      ></i>
    </div>
    <el-backtop :visibility-height="100" :bottom="80">
      <div class="back-up">UP</div>
    </el-backtop>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      currentScroll: 0,
      iframeUrl: `https://www.zz4life.com/item/wm/usChatHZTest/index.html`,
      dialogVisible: true,
    };
  },
  computed: {
    ...mapGetters(["getIframeVisible"]),
  },
  watch: {
    getIframeVisible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      // console.log("Mobile");
      // window.location.href = "http://192.168.10.183:8081";
      window.location.href = "https://www.usblindsmall.com/mobile/";
      return;
    } else {
      return;
    }
  },
  methods: {
    openOnline() {
      this.$store.commit("showIframe", true);
    },
  },
};
</script>

<style>
/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
}
/* 隐藏默认滚动条 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

page,
html,
body {
  width: 1920px;
}
@font-face {
  font-family: "MyCustomFont";
  src: url("../src/assets/HanchanSerif-Regular.otf") format("truetype");
}

body {
  font-family: "MyCustomFont", sans-serif;
}
.header {
  width: 1920px;
  padding: 0;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
}
.main-div {
  width: 100%;
  padding: 0;
  position: relative;
  left: 0;
  top: 0;
}
.main {
  width: 100%;
  padding: 0;
}
.footer {
  width: 100%;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
ul > li {
  display: flex;
  align-items: center;
}
.online-part {
  width: 388px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
  padding: 0 16px 0 13px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #74c9c6;
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
}
.all-page-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 88;
}
.iframe-visible {
  /* width: 368px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  background-color: transparent;
  z-index: 99;
}
.iframe-icon {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
}
.back-up {
  color: #475c5e;
}
/* 全局CSS END */
</style>
