<template>
  <div class="header-main">
    <div :class="!scrollFlag ? 'header-top' : 'header-top1'">
      <div class="header-top-tips">
        0086-0571-85380252 | MON - FRI 9AM - 5PM
      </div>
      <!-- <div class="header-top-btns">
        <div class="header-top-btn">
          <div>AAAAA</div>
          <div class="header-top-btn-span">aaaaaaaaaa</div>
        </div>
        <div class="header-top-yline"></div>
        <div class="header-top-btn">
          <div>BBBBB</div>
          <div class="header-top-btn-span">bbb</div>
        </div>
      </div> -->
    </div>
    <div class="nav-content">
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImg/home_icon_logo@2x.png"
        :class="scrollFlag ? 'nav-logo-hidden' : 'nav-logo-visible'"
        @click="toHome()"
      />
      <nav ref="nav">
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImg/home_icon_logo@2x.png"
          :class="!scrollFlag ? 'nav-logo2' : 'nav-logo3'"
          @click="toHome()"
        />
        <ul class="nav-ul">
          <li class="nav-li1" @click="toHome()">
            PRODUCTS
            <div class="nav-li-pro">
              <div class="pro-list">
                <div
                  class="pro-item"
                  v-for="(v, i) in proList"
                  :key="i"
                  @click.stop="toProDetails(v.productId)"
                >
                  <img :src="`${ossPrefix}${v.icon}`" class="pro-img" />
                  <span class="pro-text">{{ v.productName }}</span>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-li2" @click="toCase()">CASE</li>
          <li class="nav-li3" @click="toTrade()">T & C</li>
          <!-- <li class="nav-li4" @click="toCommercial()">COMMERCIAL</li> -->
          <!-- <li class="nav-li5" @click="toGellery()">GELLERY</li> -->
          <li class="nav-li5" @click="toService()">SERVICE</li>
          <li class="nav-li5" @click="toDocument()">DOCUMENT</li>
          <li class="nav-li5" @click="toEnvironment()">ENVIRONMENT</li>
          <!-- <li class="nav-li5" @click="toCase()">CASE</li> -->
          <li class="nav-li6" @click="toOurStory()">OUR STORY</li>
          <li class="nav-li6" @click="toContactUs()">CONTACT US</li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import "@/style/nav.css";
import { usProductList } from "@/api/home.js";
export default {
  name: "TopNav",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      currentScroll: 0,
      scrollFlag: false,
      scrollTimer: null,
      proList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
      // 获取目标元素
      const myElements = document.getElementsByClassName("nav-li1");
      const dropdown = document.getElementsByClassName("nav-li-pro")[0];

      // 添加事件监听器
      for (let i = 0; i < myElements.length; i++) {
        myElements[i].addEventListener("mouseenter", () => {
          dropdown.classList.add("show");
        });
        myElements[i].addEventListener("mouseleave", () => {
          dropdown.classList.remove("show");
        });
      }
    });
    this.getData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer) {
        clearTimeout(this.scrollTimer);
      }
      this.scrollTimer = setTimeout(() => {
        this.currentScroll = window.pageYOffset;
        let navTop = this.$refs.nav.offsetTop;
        // console.log(this.currentScroll - navTop);
        if (this.currentScroll > 0 && this.currentScroll - navTop > 23) {
          this.scrollFlag = true;
        } else {
          this.scrollFlag = false;
        }
      }, 50);
    },
    toProDetails(id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ProDetails",
        query: {
          productId: id,
        },
      });
      // 获取目标元素
      const myElements = document.getElementsByClassName("nav-li1");
      const dropdown = document.getElementsByClassName("nav-li-pro")[0];
      // 添加事件监听器
      for (let i = 0; i < myElements.length; i++) {
        dropdown.classList.remove("show");
      }
    },
    toCase() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Brands",
      });
    },
    toTrade() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Trade",
      });
    },
    toCommercial() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Commercial",
      });
    },
    toGellery() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Gellery",
      });
    },
    toService() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Service",
      });
    },
    toDocument() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Document",
      });
    },
    toEnvironment() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Environment",
      });
    },
    // toCase() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
    //   });
    //   this.$router.push({
    //     name: "Case",
    //   });
    // },
    toOurStory() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "OurStory",
      });
    },
    toContactUs() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ContactUs",
      });
    },
    toHome() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Home",
      });
    },
    getData() {
      usProductList().then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.proList = res.data.dataList;
        } else {
          this.proList = [];
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped></style>
